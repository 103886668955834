// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  Divider,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import Select, { StylesConfig, ValueType } from "react-select";
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";
import CustomFormController, {
  Props,
  Dropdown,
  InitialValues,
} from "./CustomFormController.web";

type DropdownOption = {
  value: string;
  label: string;
};

type CloseIconProps = {
  onClick: () => void;
};

const CloseIcon: React.FC<CloseIconProps> = ({ onClick }) => (
  <svg onClick={onClick} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#334155" />
  </svg>
)

const FormTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    fontFamily: "DIN Round Pro",
    fontSize: "16px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "22px 10px"
  }
})

const SelectField = styled(Select)({
  maxWidth: "230px",
  fontFamily: "DIN Round Pro",  
})
  
const customStyles = (hasError: boolean): StylesConfig<DropdownOption, false> => ({
  indicatorSeparator: () => ({
    display: 'none', 
  }),
  control: (provided) => ({
    ...provided,
    borderColor: hasError ? 'red' : provided.borderColor, 
    padding: '3px 0px',
    borderRadius: '8px',
    maxWidth: '230px',
    fontFamily: 'DIN Round Pro',
    boxShadow: "none",
    '&:hover': {
      borderColor: hasError ? 'red' : provided.borderColor,
      boxShadow: "none"
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: 'DIN Round Pro',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    color: "#666666 !important"
  }),
});

const SubmitButton1 = styled(Button)({
  backgroundColor: "#FF9E1B",
  color: "white",
  borderRadius: "8px",
  textTransform: "none",
  fontFamily: "DIN Round Pro",
  fontSize: "16px",
  fontWeight: 700,
  padding: "8px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "#FF9E1B",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  styles = {
    heading: {
      fontFamily: "DIN Round Pro Bold",
      fontWeight: 700,
      fontSize: "24px",
    },
    subHeading: {
      fontFamily: "DIN Round Pro",
      fontWeight: 500,
      fontSize: "20px",
      borderBottom: "2px solid #94A3B8"
    },
  }

  userSchema = () => {
    return Yup.object().shape({
      customerName: Yup.string().required("This field is mandatory."),
      emailAddress: Yup.string().email("Must be an email").required("This field is mandatory."),
      website: Yup.string().required("This field is mandatory."),
      industry: Yup.string().required("This field is mandatory."),
      employeesNumber: Yup.string().matches(/^\d+$/, "Must be an integer").required("This field is mandatory."),
      priority: Yup.string().required("This field is mandatory."),
      street: Yup.string().required("This field is mandatory."),
      telephone: Yup.string().matches(/^\d+$/, "Must be an integer").required("This field is mandatory."),
      country: Yup.string().required("This field is mandatory."),
      city: Yup.string().required("This field is mandatory."),
      postcode: Yup.string().matches(/^\d+$/, "Must be an integer").required("This field is mandatory."),
      role: Yup.string().required("This field is mandatory."),
      salesPerson: Yup.string().required("This field is mandatory."),
      contactNumber: Yup.string().required("This field is mandatory."),
      type: Yup.string().required("This field is mandatory."),
      email: Yup.string().required("This field is mandatory."),
      mobileNumber: Yup.string().required("This field is mandatory."),
      file: Yup.string().required("This field is mandatory."),
      phoneNumber: Yup.string().required("This field is mandatory."),
      designation: Yup.string().required("This field is mandatory."),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<InitialValues>,
    errors: FormikErrors<InitialValues>,
    value: string
  ) => {
    return (
      touched[value as keyof InitialValues] &&
      errors[value as keyof InitialValues] && (
        <Typography
          id="errorMessage"
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b", fontFamily: "DIN Round Pro" }}
        >
          {errors[value as keyof InitialValues]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  fieldLabel = (title: string, required: boolean = true) => {
    return (
      <div style={{ marginBottom: "5px", fontSize: "14px", fontFamily: "DIN Round Pro", fontWeight: 700 }}>
        {title}
        {required && <span style={{ color: "red" }}> *</span>}
      </div>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box margin="35px 30px">
        {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
        <Dialog
          onClose={this.props.onClose}
          data-test-id="dialog"
          open={true}
          fullWidth={false}
          className="automated-prioritization"
          maxWidth={"lg"}
          transitionDuration={0}
          scroll="body"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1094px"
              },
            },
          }}
        >
          <DialogContent
            style={{ padding: '0px', width: '100%' }}
            data-test-id="form"
          >
            <Box padding={{ xs: "15px 25px 25px", sm: "24px 40px 24px 40px" }}>
              <Box id="modal-title" style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                <Typography style={this.styles.heading}> New Client </Typography>
                <CloseIcon data-test-id="closeIcon" onClick={this.props.onClose} />
              </Box>
              <Divider style={{ color: "#E2E8F0", marginTop: "24px", marginLeft: "-40px", marginRight: "-40px"}}/>
              
              <Formik
                data-test-id="formik"
                initialValues={this.state.initialValues}
                validationSchema={this.userSchema}
                onSubmit={(values) => {
                  this.state.openDialogName === "Add"
                    ? this.onAddTask()
                    : this.onEditTask();
                }}
              >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                  <form style={{marginBottom: "0"}} onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Box style={{ margin: "28px 0px" }}>
                      <span style={this.styles.subHeading}>Customer Information</span>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Customer Name (as per ACRA)")}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "customerName")}
                          id="customerName"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: { height: "0px" },
                          }}
                          onChange={(event) => {
                            this.onValueChange("customerName", event.target.value);
                            setFieldValue("customerName", event.target.value);
                          }}
                          value={this.state.initialValues.customerName}
                          placeholder="Enter Customer Name"
                        />
                        {this.getErrorMessage(touched, errors, "customerName")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Email Address")}
                        <FormTextField
                          style={{maxWidth: "230px"}}
                          error={this.handleFieldError(touched, errors, "emailAddress")}
                          fullWidth
                          id="emailAddress"
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          value={this.state.initialValues.emailAddress}
                          placeholder="Enter Email Address"
                          onChange={(event) => {
                            this.onValueChange("emailAddress", event.target.value);
                            setFieldValue("emailAddress", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "emailAddress")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Website")}
                        <FormTextField
                          id="website"
                          error={this.handleFieldError(touched, errors, "website")}
                          style={{maxWidth: "230px"}}
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Website"
                          value={this.state.initialValues.website}
                          onChange={(event) => {
                            this.onValueChange("website", event.target.value);
                            setFieldValue("website", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "website")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Industry")}
                        <SelectField
                          styles={customStyles((this.handleFieldError(touched, errors, "industry")))}
                          id="industry"
                          classNamePrefix="react-select"
                          placeholder="Select Industry"
                          isSearchable={false}
                          options={[]}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.industry
                            )[0]
                          }
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const fieldValue = this.getValue(event);
                            this.onValueChange("industry", fieldValue);
                            setFieldValue("industry", fieldValue);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "industry")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("No Of Employees")}
                        <FormTextField
                          style={{maxWidth: "230px"}}
                          error={this.handleFieldError(touched, errors, "employeesNumber")}
                          id="employeesNumber"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter No of employees"
                          value={this.state.initialValues.employeesNumber}
                          onChange={(event) => {
                            this.onValueChange("employeesNumber", event.target.value);
                            setFieldValue("employeesNumber", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "employeesNumber")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Priority")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "priority"))}
                          id="priority"
                          className="react-select"
                          placeholder="Select Priority"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.priority
                            )[0]
                          }
                          options={[]}
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const value = this.getValue(event);
                            this.onValueChange("priority", value);
                            setFieldValue("priority", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "priority")}
                      </Grid>
                    </Grid>

                    <Box style={{ margin: "28px 0px" }}>
                      <span style={this.styles.subHeading}>Address</span>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={9} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Street")}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "street")}
                          id="street"
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          value={this.state.initialValues.street}
                          placeholder="Enter Street"
                          onChange={(event) => {
                            this.onValueChange("street", event.target.value);
                            setFieldValue("street", event.target.value);
                          }}
                          fullWidth
                        />
                        {this.getErrorMessage(touched, errors, "street")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Telephone")}
                        <FormTextField
                          id="telephone"
                          style={{maxWidth: "230px"}}
                          fullWidth
                          variant="outlined"
                          error={this.handleFieldError(touched, errors, "telephone")}
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Telephone"
                          value={this.state.initialValues.telephone}
                          onChange={(event) => {
                            this.onValueChange("telephone", event.target.value);
                            setFieldValue("telephone", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "telephone")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Country")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "country"))}
                          options={[]}
                          id="county"
                          classNamePrefix="react-select"
                          placeholder="Select Country"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.country
                            )[0]
                          }
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const value = this.getValue(event);
                            this.onValueChange("country", value);
                            setFieldValue("country", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "country")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("City")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "city"))}
                          id="city"
                          classNamePrefix="react-select"
                          placeholder="Select City"
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.city
                            )[0]
                          }
                          isSearchable={false}
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const cityValue = this.getValue(event);
                            this.onValueChange("city", cityValue);
                            setFieldValue("city", cityValue);
                          }}
                          options={[]}
                          />
                        {this.getErrorMessage(touched, errors, "city")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Postcode")}
                        <FormTextField
                          fullWidth
                          style={{maxWidth: "230px"}}
                          id="postcode"
                          variant="outlined"
                          error={this.handleFieldError(touched, errors, "postcode")}
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Postcode"
                          value={this.state.initialValues.postcode}
                          onChange={(event) => {
                            this.onValueChange("postcode", event.target.value);
                            setFieldValue("postcode", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "postcode")}
                      </Grid>

                    </Grid>

                    <Box style={{ margin: "28px 0px" }}>
                      <span style={this.styles.subHeading}>Sales Person</span>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Role")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "role"))}
                          id="role"
                          classNamePrefix="react-select"
                          options={[]}
                          placeholder="Select Role"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.role
                            )[0]
                          }
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const roleValue = this.getValue(event);
                            this.onValueChange("role", roleValue);
                            setFieldValue("role", roleValue);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "role")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: '230px' }}>
                        {this.fieldLabel("Sales Person Name")}
                        <FormTextField
                          placeholder="Enter Sales Person Name"
                          error={this.handleFieldError(touched, errors, "salesPerson")}
                          style={{maxWidth: "230px"}}
                          id="salesPerson"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          value={this.state.initialValues.salesPerson}
                          onChange={(event) => {
                            this.onValueChange("salesPerson", event.target.value);
                            setFieldValue("salesPerson", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "salesPerson")}
                      </Grid>

                    </Grid>

                    <Box style={{ margin: "28px 0px" }}>
                      <span style={this.styles.subHeading}>Customer Contact</span>
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Contact Name")}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "contactNumber")}
                          style={{maxWidth: "230px"}}
                          id="contactNumber"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Contact Name"
                          value={this.state.initialValues.contactNumber}
                          onChange={(event) => {
                            this.onValueChange("contactNumber", event.target.value);
                            setFieldValue("contactNumber", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "contactNumber")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Type")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "type"))}
                          id="type"
                          classNamePrefix="react-select"
                          placeholder="Select Type"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.type
                            )[0]
                          }
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const typeValue = this.getValue(event);
                            this.onValueChange("type", typeValue);
                            setFieldValue("type", typeValue);
                          }}
                          options={[]}
                        />
                        {this.getErrorMessage(touched, errors, "type")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Email")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "email"))}
                          classNamePrefix="react-select"
                          placeholder="Select Email"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.email
                            )[0]
                          }
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const emailValue = this.getValue(event);
                            this.onValueChange("email", emailValue);
                            setFieldValue("email", emailValue);
                          }}
                          id="email"
                          options={[]}
                        />
                        {this.getErrorMessage(touched, errors, "email")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Mobile No.")}
                        <SelectField
                          styles={customStyles(this.handleFieldError(touched, errors, "mobileNumber"))}
                          id="mobileNumber"
                          classNamePrefix="react-select"
                          placeholder="Select Mobile No"
                          isSearchable={false}
                          value={
                            userTypeList.filter(
                              (option: Dropdown) =>
                                option.value === this.state.initialValues.mobileNumber
                            )[0]
                          }
                          options={[]}
                          onChange={(event: ValueType<Dropdown, false>) => {
                            const value = this.getValue(event);
                            this.onValueChange("mobileNumber", value);
                            setFieldValue("mobileNumber", value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "mobileNumber")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Phone No")}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "phoneNumber")}
                          style={{maxWidth: "230px"}}
                          id="phoneNumber"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Phone No"
                          value={this.state.initialValues.phoneNumber}
                          onChange={(event) => {
                            this.onValueChange("phoneNumber", event.target.value);
                            setFieldValue("phoneNumber", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "phoneNumber")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Designation")}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "designation")}
                          style={{maxWidth: "230px"}}
                          id="designation"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Designation"
                          value={this.state.initialValues.designation}
                          onChange={(event) => {
                            this.onValueChange("designation", event.target.value);
                            setFieldValue("designation", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "designation")}
                      </Grid>
                      <Grid item xs={3} style={{ minWidth: "230px" }}>
                        {this.fieldLabel("Remarks", false)}
                        <FormTextField
                          error={this.handleFieldError(touched, errors, "remarks")}
                          style={{maxWidth: "230px"}}
                          id="remarks"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Enter Remarks"
                          value={this.state.initialValues.remarks}
                          onChange={(event) => {
                            this.onValueChange("remarks", event.target.value);
                            setFieldValue("remarks", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "remarks")}
                      </Grid>
                      <Grid style={{ minWidth: "230px" }} item xs={3}>
                        {this.fieldLabel("Image", false)}
                        <FormTextField
                          style={
                            { maxWidth: "230px" }
                          }
                          error={this.handleFieldError(touched, errors, "image")}
                          id="image"
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "0px",
                            },
                          }}
                          placeholder="Select Image"
                          value={this.state.initialValues.image}
                          onChange={(event) => {
                            this.onValueChange("image", event.target.value);
                            setFieldValue("image", event.target.value);
                          }}
                        />
                        {this.getErrorMessage(touched, errors, "image")}
                      </Grid>

                    </Grid>

                    <Box mt="30px" display="flex">
                      <SubmitButton1 type="submit" id="saveBtn">
                        Save
                      </SubmitButton1>
                    </Box>


                  </form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
