import React from "react";

// Customizable Area Start
import { Box, Typography, Paper, Grid, Button, FormControl, Select, MenuItem, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  LinearProgress,
} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import IconButton from '@mui/material/IconButton';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from "recharts";
import {
  createTheme,
  styled

} from "@mui/material/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { button_icon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const Containerbox = styled(Box)({

  width: '100vw',
  height: '100vh',
  display: "flex",
})
const TableCellstyle = styled(TableCell)({
  backgroundColor: '#F1F5F9',
  border: '4px solid white',


})

const Cardstypo = styled(Typography)({
  color: '#334155'
  , fontSize: '18px',
  fontFamily: 'Din Round Pro Bold'
})
const CardstypoValue = styled(Typography)({
  color: '#334155'
  , fontSize: '36px',
  fontFamily: 'Din Round Pro Bold'
})

const CardsdollerValues = styled(Typography)({
  color: '#1E293B'
  , fontSize: '14px',
  fontFamily: 'Din Round Pro Bold'
})
const Cardsdollerquantity = styled(Typography)({
  color: '#64748B'
  , fontSize: '14px',
  fontFamily: 'Din Round Pro Bold'
})

const TotalGDrevBlock = styled(Typography)({
  color: '#000000'
  , fontSize: '20px',
  fontFamily: 'Din Round Pro Bold'
})

const TotalGDrevamount = styled(Typography)({
  color: '#334155'
  , fontSize: '20px',
  fontFamily: 'Din Round Pro Bold'
})
// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController.web";

export default class LandingPageWeb extends LandingPageController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start


  const { isVisible } = this.state;


    return (
      <>
        <Containerbox >
          <Grid item lg={10} md={10} sm={12} >
            <Grid container>
          <Grid 
            item 
            lg={isVisible ? 1 : 2}
          md={isVisible ? 1 : 2}
            sx={{ 
           transition: 'width 0.3s ease',
           "@media (max-width: 1299px)": {
            display: "none",
          },
      }}
          >
          </Grid>
              <Grid item sm={6} xs={12} md={10} lg={10}>
                <Box style={{ border: '1px solid #EBEEF1 ', padding: '15px',position: 'relative'}} mb={3} display={'flex'}>
                <IconButton className="collapse_button" onClick={this.handleClick} size="small" 
                sx={{position:'absolute',top:'40px',left:'20px',transform:'translateX(-100%)',cursor:'pointer',zIndex:1}}>   
                    <img src={button_icon.default} style={{width:'32px',height:"32px"}}/>
                </IconButton>
                  <Typography style={{ color: '#ooooo', fontWeight: 900, fontSize: '20px' }}>Dashboard</Typography>
                </Box>
                <Grid container spacing={2} mb={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        boxShadow: theme.shadows[2],
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Cardstypo >Year Target</Cardstypo>
                        <CardstypoValue sx={{ mt: 1 }}>
                          $25,000
                        </CardstypoValue>
                      </Box>
                      <IconButton size="small" sx={{boxShadow:2,borderRadius:1}}>
                        <EditOutlinedIcon style={{ color: '#ff9800' }} />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      sx={{
                        p: 2,
                        boxShadow: theme.shadows[2],
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Cardstypo mb={1.5}>Quarterly Target</Cardstypo>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box display={'flex'}>
                            <Cardsdollerquantity > Q1</Cardsdollerquantity>
                            <CardsdollerValues > $10,000</CardsdollerValues>
                           </Box>
                         </Grid>
                        <Grid item xs={6}>
                          <Box display={'flex'}>
                            <Cardsdollerquantity> Q2</Cardsdollerquantity>
                            <CardsdollerValues > $12,000</CardsdollerValues>
                          </Box>

                        </Grid>
                        <Grid item xs={6}>
                          <Box display={'flex'}>
                            <Cardsdollerquantity> Q3</Cardsdollerquantity>
                            <CardsdollerValues > $15,000</CardsdollerValues>
                          </Box> </Grid>
                        <Grid item xs={6}>
                          <Box display={'flex'}>
                            <Cardsdollerquantity> Q4</Cardsdollerquantity>
                            <CardsdollerValues > $20,000</CardsdollerValues>
                          </Box>
                        </Grid></Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        boxShadow: theme.shadows[2],
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Cardstypo >Monthly Target</Cardstypo>
                        <CardstypoValue sx={{ mt: 1 }}>
                          $5,000
                        </CardstypoValue>
                      </Box>
                      <IconButton size="small" sx={{boxShadow:2,borderRadius:1}}>
                        <EditOutlinedIcon style={{ color: '#ff9800' }} />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Box
                      sx={{
                        p: 2,
                        boxShadow: theme.shadows[2],
                        borderRadius: '8px',
                        backgroundColor: '#CEE884',
                      }}>
                      <Cardstypo >Pipeline for the Month</Cardstypo>
                      <CardstypoValue sx={{ mt: 1 }}>$15,000</CardstypoValue>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={3}>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <Paper elevation={2} style={{ padding: '15px' }} >
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <TotalGDrevBlock>Total GP Achieved Till Date</TotalGDrevBlock>
                        <TotalGDrevamount>$50,000</TotalGDrevamount>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item sm={6} xs={12} md={6} lg={6}>
                    <Paper elevation={2} style={{ padding: '15px' }} >
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <TotalGDrevBlock>Total Rev Achieved Till Date</TotalGDrevBlock>
                        <TotalGDrevamount>$50,000</TotalGDrevamount>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid >

                <Grid container spacing={2} mb={3}>
                  <Grid item sm={12} xs={12} md={12} lg={6}>
                    <Paper elevation={2} style={{ padding: '15px' }} >
                      <Box display={'flex'} justifyContent={'space-between'}>
                      <Box display={'flex'} gap={1}>
                        <Typography>Total Target </Typography>
                        <FormControl sx={{
    width: '100px',
    marginBottom: '15px',
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", 
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray", 
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
         paddingRight:'32px',
         border:'1px solid grey'
} 
  }}
                          style={{ width: '100px', marginBottom: '15px',border:'1px solid gery' }} 
                          size="small" 
                          variant='outlined' 
                        >

                          <Select label="Year"value={2024}>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem value={2024}>2024</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box display={"flex"}>
                        <Box display={'flex'}>  <FiberManualRecordIcon sx={{color:'#ffBE66'}}/><Typography>Target</Typography></Box>
                       
                       <Box display={'flex'}> <FiberManualRecordIcon sx={{color:'#B57013'}}/> <Typography> Achieved</Typography></Box>
                      </Box> </Box>
                      <Box sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <BarChart
                            width={530}
                            height={300}
                            data={this.state.data}
                            margin={{
                              top: 20, right: 30, left: 20, bottom: 5,
                            }} >
                            <CartesianGrid horizontal={true}
                             vertical={false} 
                             strokeDasharray='3 3' />
                            <XAxis dataKey="name" />
                            <Tooltip />
                            <Bar dataKey="Target" fill="#ffBE66" />
                            <Bar dataKey="Archived" fill="#B57013" />
                          </BarChart>
                        </ResponsiveContainer>
                      </Box></Paper></Grid>
                  <Grid item sm={12} xs={12} md={12} lg={6}>
                    <Paper elevation={2} style={{ padding: '15px' }} >
                      <Box display={'flex'} justifyContent={'space-between'} >
                      <Box display={'flex'} gap={1}>
                        <Typography>Archieved Target Per Month </Typography>
                        <FormControl  sx={{ width: '100px', marginBottom: '15px', "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", 
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray", 
    },
    "& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
         paddingRight:'32px',
         border:'1px solid grey'} }}
                          style={{ width: '100px', marginBottom: '15px' }}
                          size="small"
                          variant="outlined">
                          <Select label="Year" value={2024}>
                            <MenuItem 
                            value={2021}>2021</MenuItem>
                            <MenuItem 
                            value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                            <MenuItem 
                            value={2024}>2024</MenuItem>

                          </Select>
                        </FormControl>
                      </Box>
                      <Box display={"flex"}>
                        <Box display={'flex'}> 
                          <FiberManualRecordIcon sx={{color:'#E89019'}}/><Typography>Target</Typography></Box>
                       
                       <Box display={'flex'}>
                        <FiberManualRecordIcon sx={{color:'#5979AE'}}/> <Typography> Achieved</Typography></Box>
                       

                      </Box>
                      </Box>
                      <Box sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                          <LineChart data={this.state.lineardata}>
                            <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                            <XAxis dataKey="month" />
                            <YAxis ticks={[10, 20, 30, 40]} />
                            <Tooltip />
                            <Line type="monotone" dataKey="value1" stroke="#E89019" />
                            <Line type="monotone" dataKey="value2" stroke="#5979AE" />
                          </LineChart>
                        </ResponsiveContainer>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid >
              </Grid>
              <TableContainer sx={{ boxShadow: 3 }}>
                <Grid container >
                  <Grid lg={8} md={12} sm={12} xs={12}>

                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                      <Typography style={{ fontWeight: 600, fontSize: '25px' }}>Task</Typography>
                      <Button variant="contained" size="medium" style={{ backgroundColor: 'F09B1B' }}>
                        + Add Task
                      </Button>
                    </Box>

                  </Grid>

                </Grid>





                <Table>
                  <TableHead style={{ border: '2px solid green', backgroundColor: "#FFFFF", }}>
                    <TableRow >
                      <TableCellstyle >Task</TableCellstyle>
                      <TableCellstyle>Specific</TableCellstyle>
                      <TableCellstyle>Time Bond</TableCellstyle>
                      <TableCellstyle>Measurable</TableCellstyle>
                      <TableCellstyle>Attainable</TableCellstyle>
                      <TableCellstyle>Relevant</TableCellstyle>
                      <TableCellstyle>Task Completed</TableCellstyle>
                      <TableCellstyle>Due Date</TableCellstyle>
                      <TableCellstyle></TableCellstyle>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.tasks.map((task: any, indexx: any) => (
                      <TableRow key={indexx} >
                        <TableCell >{task.task}</TableCell>
                        <TableCell>{task.specific}</TableCell>
                        <TableCell>{task.timeBond}</TableCell>
                        <TableCell>{task.measurable}</TableCell>
                        <TableCell>{task.attainable}</TableCell>
                        <TableCell>{task.relevant}</TableCell>
                        <TableCell>
                          <LinearProgress variant="determinate" value={task.progress} style={{ width: '100px' }} sx={{
                            width: '100px',
                            backgroundColor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#32D39A',
                            },
                          }} />
                          <Typography variant="caption">{task.progress}%</Typography>
                        </TableCell>
                        <TableCell>{task.date}</TableCell>
                        <TableCell>
                          <IconButton>
                            <ChatBubbleOutlineIcon />
                          </IconButton>
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>


                <Grid container>
                  <Grid lg={8} md={12} sm={12} xs={12}>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem' }}>
                      <IconButton>
                        <ArrowForwardIcon />
                      </IconButton>
                      <Button style={{ textDecoration: 'underline', color: '#667188' }}>View All</Button>
                    </Box>

                  </Grid>

                </Grid>
              </TableContainer>

            </Grid>
          </Grid>
        </Containerbox>
      </>

    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
