import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data:any
  lineardata:any
  tasks:any
  isVisible:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      data : [
        { name: 'Managed infra', Target: 5000, Archived: 3000, amt: 3000 },
        { name: 'Cloud', Target: 9000, Archived: 7000, amt: 2210 },
        { name: 'Enterprise Computing', Target: 10000, Archived: 4000, amt: 2290 },
      ],
      lineardata:[
        { month: 'Jan', value1: 19.5, value2: 20.5},
        { month: 'Feb', value1: 24.5, value2: 19.7 },
        { month: 'Mar', value1: 26.2, value2: 21.7},
        { month: 'Apr', value1: 22.4, value2: 27.1},
        { month: 'May', value1: 23.7, value2: 24.6},
        { month: 'Jun', value1: 27.3, value2:24.5},
        { month: 'Jul',value2: 22.2},
        { month: 'aug',value2: 23.1},
        { month: 'sep',value2: 22.2},
        { month: 'oct',value2: 29.9},
        { month: 'Nov',value2: 31.6},
        { month: 'Dec',value2: 31.68},
      ],tasks : [
        { task: 'Lorem Ipsum Dolor', specific: 'Dummy', timeBond: 'Dummy', measurable: 'Dummy', attainable: 'Dummy', relevant: 'Dummy', progress: 75, date: '02/08/2021' },
        { task: 'Lorem Ipsum Dolor', specific: 'Dummy', timeBond: 'Dummy', measurable: 'Dummy', attainable: 'Dummy', relevant: 'Dummy', progress: 25, date: '12/06/2020' },
        { task: 'Lorem Ipsum Dolor', specific: 'Dummy', timeBond: 'Dummy', measurable: 'Dummy', attainable: 'Dummy', relevant: 'Dummy', progress: 50, date: '12/06/2020' }
      ],
      isVisible:true
    }

    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleClick = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };
  // Customizable Area End
}
