import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Paper,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
const Row = [
  { id: 1, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 2, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 3, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 4, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 5, salesrepname: 'Sa1111 Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 6, salesrepname: 'Sa1111 Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },
  { id: 7, salesrepname: 'Sa1111 Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target' },

];

const columns = [
  { field: 'salesrepname', headerName: 'Month', width: 300 },
  { field: 'currentyear', headerName: 'Target Sales Rev', width: 300 },
  { field: 'YearlyTarget', headerName: 'Achieved Sales Rev', width: 300 },
  { field: 'QuarterlyTarget', headerName: 'Target Profit', width: 300 },
  { field: 'MonthlyTarget', headerName: 'Achived Profit', width: 300 },

];
const tabtopcolumn = [
  { field: 'salesrepname', headerName: 'Month', width: 300 },
  { field: 'currentyear', headerName: 'Target Sales Rev', width: 300 },
  { field: 'YearlyTarget', headerName: 'Achieved Sales Rev', width: 300 },
  { field: 'QuarterlyTarget', headerName: 'Target Profit', width: 300 },
  { field: 'MonthlyTarget', headerName: 'Achived Profit', width: 300 },
  {
    field: 'more',
    headerName: '',
    width: 100,
    renderCell: (params:any) => (
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    ),
  },

];



const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>

          <Box style={{ backgroundColor: '#F1F5F9' }}>

            <Paper style={webStyles.tableViewStyle}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <ArrowBackIcon />
                  <Typography style={webStyles.Heading}>Team Performance </Typography>
                </Box>
                <Button
                  variant="contained"
                  size="medium"
                  data-test-id="btnAddTaskListModal"
                  style={{ backgroundColor: 'F09B1B', height: "44px", borderRadius: "8px", color: "#fffff", fontSize: "16px", fontFamily: 'Din Round Pro Bold' }}>
                  + Add
                </Button>
              </Box>
            </Paper>
            <Box>
              <Paper style={{ margin: '20px', padding: "10px", display: "flex", height: "500", flexDirection: "column", }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "30px", marginTop: "20px" }}>
                  <SearchIcon sx={{ alignSelf: "center" }} />
                  <TextField variant="outlined" size="small"
                    placeholder="Column display"
                    data-test-id={"inputName"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FilterListOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      alignSelf: "center",
                      height: "32px",
                      '& input::placeholder': {
                        fontSize: '16px',
                        color: '#64748B'
                      },
                    }}>
                  </TextField>
                </Box>
                <DataGrid
                  rows={Row}
                  columns={tabtopcolumn}
                  disableColumnResize
                  sx={{

                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                      backgroundColor: '#F1F5F9 !important',
                      color: '#0F172A !important',
                      fontSize: '16px',
                      justifyContent: "center",
                      alignItems: "center",

                    },
                    '& .MuiDataGrid-cell': {
                      justifyContent: 'center',
                      textAlign: 'center',

                    },
                    '& .MuiDataGrid-columnSeparator': {
                      display: 'none',

                    },
                    '& .MuiDataGrid-menuIcon': {
                      display: 'none',

                    },
                    '& .MuiDataGrid-menuOpen': {
                      visibility: 'hidden',
                    }
                  }}
                  sortingOrder={['asc', 'desc']}
                />
                {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu> */}
              </Paper>
              <input data-test-id="inputTitle" style={{display: "none"}}>
              </input>
              <input data-test-id="inputDescription" style={{display: "none"}}>
              </input>
            </Box>
            <Paper style={{ margin: '20px', padding: "10px", display: "flex", flexDirection: "column", height: "500" }} data-test-id="btnHandleAssignTo">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px", marginTop: "20px", }} data-test-id="btnHideAssignModal">

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%' }} data-test-id="btnExpandAccountsView">
                  <Typography data-test-id="btnExpandGroupView">Monthly Target</Typography>
                </Box>
                <Button
                  variant="contained"
                  size="medium"
                  data-test-id="btnAddTaskListModal"
                  style={{ backgroundColor: 'F09B1B', height: "44px", borderRadius: "8px", color: "#fffff", fontSize: "16px", fontFamily: 'Din Round Pro Bold' }}>
                  <EditIcon /> Edit
                </Button>
              </Box>
              <DataGrid
                columns={columns}
                rows={Row}
                data-test-id="btnAssignTaskToGroup0"
                disableColumnResize
                sx={{
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    color: '#0F172A !important',
                    justifyContent: "center",
                    backgroundColor: '#F1F5F9 !important',
                    fontSize: '16px',
                    alignItems: "center",

                  },
                  '& .MuiDataGrid-menuIcon': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    textAlign: 'center',
                    justifyContent: 'center',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-menuOpen': {
                    visibility: 'hidden',
                  }
                }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
            <Paper style={{ margin: '20px', flexDirection: "column", height: "500",  padding: "10px", display: "flex", }} data-test-id="btnAssignTo0">
              <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "30px", marginTop: "20px", alignItems: "center",}} data-test-id="btnEditTask0">
                <Typography data-test-id="btnDeleteTask0">Quarterly Target</Typography>
              </Box>
              <DataGrid
                data-test-id="btnAssignTaskToAccount0"
                rows={Row}
                disableColumnResize
                columns={columns}
                sx={{
                  height: "100%",
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    color: '#0F172A !important',
                    backgroundColor: '#F1F5F9 !important',
                    alignItems: "center",
                    fontSize: '16px',
                    justifyContent: "center",
                  },
                  '& .MuiDataGrid-menuIcon': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-menuOpen': {
                    visibility: 'hidden',
                  },
                  '& .MuiDataGrid-cell': {
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
            <Paper style={{ margin: '20px', padding: "10px", display: "flex", flexDirection: "column", height: "500" }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px", marginTop: "20px", }} data-test-id="btnAddTask">
                <Typography data-test-id="btnCloseModal">Yearly Target</Typography>
              </Box>
              <DataGrid
                rows={Row}
                columns={columns}
                disableColumnResize
                sx={{
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    backgroundColor: '#F1F5F9 !important',
                    color: '#0F172A !important',
                    fontSize: '16px',
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  '& .MuiDataGrid-cell': {
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-menuIcon': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-menuOpen': {
                    visibility: 'hidden',
                  },
                  '& .MuiDataGrid-root': {
                    padding: 0,
                    margin: 0,
                    height: 'calc(100vh - 180px)',
                  },
                  '& .MuiDataGrid-virtualScroller': {
                    paddingBottom: 0,
                  },
                  '& .MuiDataGrid-footerContainer': {
                    display: 'none',
                  },
                }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "30px", marginTop: "20px", }} data-test-id="btnAssignPriority0">
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%' }} data-test-id="btnAssignStatus0">
                <Link href="#" underline="always" data-test-id="btnExpandStatusView">
                  {'View Historical Data'}
                </Link>
                <IconButton data-test-id="btnExpandPriorityView"><TrendingFlatIcon /></IconButton>
              </Box>
              <Button data-test-id="btnGetTasks" variant="outlined" sx={{ minWidth: 150 }}
                startIcon={<SaveAltIcon />}>
                Download csv
              </Button>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  Heading: {
    color: '#0F172A',
    fontSize: '24px',
    fontFamily: 'Din Round Pro Bold',
    marginLeft: "20px"
  }
};
// Customizable Area End
