import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { ITask, ITaskList } from "./types";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
const rows = [
  { id: 1, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  { id: 2, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  { id: 3, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  { id: 4, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  { id: 5, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  { id: 6, salesrepname: 'Sales Rep Name', currentyear: 'Current Year', YearlyTarget: 'Yearly Target', QuarterlyTarget: 'Quarterly Target', MonthlyTarget: 'Month Target', TargetAchieved: 'Target Achieved'},
  // Add more rows as needed
];

const columns = [
  { field: 'salesrepname', headerName: 'Sales Rep Name',width:220},
  { field: 'currentyear', headerName: 'Current Year',width:220},
  { field: 'YearlyTarget', headerName: 'Yearly Target',width:220},
  { field: 'QuarterlyTarget', headerName: 'Quarterly Target',width:220},
  { field: 'MonthlyTarget', headerName: 'Monthly Target',width:220},
  { field: 'TargetAchieved', headerName: 'Target Achieved ',width:220},
];

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box style={{ backgroundColor: '#F1F5F9' }}>
            <Paper style={webStyles.tableViewStyle}>
              <Box style={{ display: 'flex', padding: '1rem', justifyContent: 'space-between', }} data-test-id="btnDeleteTaskList">
                <Box justifyContent={'center'} alignItems={'center'} display={'flex'} data-test-id="btnCloseModal">
                  <ArrowBackIcon data-test-id="btnGetTaskLists" />
                  <Typography style={webStyles.Heading} data-test-id="btnEditTaskList">Team Performance </Typography>
                </Box>
                <Button
                  variant="contained"
                  size="medium"
                  data-test-id="btnAddTaskListModal"
                  style={{ backgroundColor: 'F09B1B', height: "44px", borderRadius: "8px", color: "#fffff", fontSize: "16px", fontFamily: 'Din Round Pro Bold' }}>
                  + Add
                </Button>
              </Box>
            </Paper>

            <Paper style={{ margin: '20px', padding: "10px" }}>
              <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px", marginTop: "20px", justifyContent: "flex-end", }} data-test-id="btnHandleTasksSelect0">
                <SearchIcon sx={{ alignSelf: "center" }} data-test-id="btnAddTaskList" />
                <TextField size="small" variant="outlined"
                  placeholder="Column display"
                  data-test-id="inputName"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterListOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    height: "32px",
                    alignSelf: "center",
                    '& input::placeholder': {
                      fontSize: '16px',
                      color: '#64748B'
                    },
                  }}>
                </TextField>
              </Box>
              <DataGrid
                columns={columns}
                data-test-id="btnExpandTasksView"
                rows={rows}
                disableColumnResize
                sx={{
                  '& .MuiDataGrid-menuOpen': {
                    visibility: 'hidden',
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainer': {
                    backgroundColor: '#F1F5F9 !important',
                    color: '#0F172A !important',
                    fontSize: '16px',
                    justifyContent: "center",
                    alignItems: "center",
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-menuIcon': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    justifyContent: 'center',
                    textAlign: 'center',
                  },
                }}
                sortingOrder={['asc', 'desc']}
              />
            </Paper>
          </Box>

        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px",
  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
  },
  tableContainerStyle: {
    // maxHeight: 440,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
  Heading:{
    color: '#0F172A',
    fontSize: '24px',
    fontFamily: 'Din Round Pro Bold',
    marginLeft:"20px"
  }
};
// Customizable Area End
